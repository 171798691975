import React, {useState} from 'react';
import classes from './OnChainDepositAddress.module.css';
import {Trans, useTranslation} from "react-i18next";
import {useGetDepositAddress} from "../../../../../../query";
import toast from "react-hot-toast";
import moment from "moment-jalaali";
import Button from "../../../../../../components/Button/Button";
import QRCode from "react-qr-code";
import Icon from "../../../../../../components/Icon/Icons";
import Countdown from "react-countdown";
import useGlobal from "../../../../../../Hooks/useGlobal";
import Date from "../../../../../../components/Date/Date";
import MobileComp from "../../../../../../components/MobileComp/MobileComp";

const OnChainDepositAddress = () => {

    const {t} = useTranslation();
    const {global, setGlobal} = useGlobal();
    const gateway = global?.selected_deposit_network

    const [expired, setExpired] = useState(false)

    const {data, isLoading, error, refetch ,isRefetching} = useGetDepositAddress(gateway?.currencySymbol, gateway?.chain)

    const copyToClipboard = (e, value) => {
        e.stopPropagation();
        e.preventDefault();
        navigator.clipboard.writeText(value)
        toast.success(t("copy"));
    }

    const content = () => {

        if (isLoading || isRefetching)  {
            return <>
                <div className={`${classes.rectangle} width-65 rounded-8`}/>
                <div className={`${classes.rectangle2} width-85 rounded-8 mt-3`}/>

            </>
        }
        if (error) {



            return <span className={`my-10 width-100 text-center`} >
                {
                    error?.response?.data?.message === "ReservedAddressNotAvailable" ? <div className={`width-100 rounded-8 row jc-center ai-center ${classes.deniedBox} px-5 py-1 mt-1 mb-4 position-relative`}>
                        <span>{t("Deposit.ReservedAddressNotAvailable")}</span>
                    </div> : t("error")
                }
            </span>
        }

        if (data?.length <= 0) {
            return <div className={`width-100 rounded-8 row jc-center ai-center ${classes.deniedBox} px-5 py-1 mt-1 mb-4 position-relative`}>
                <span>{t("Deposit.ReservedAddressNotAvailable")}</span>
            </div>
        }

        if (expired) {
            return <>

                <div className={`width-100 rounded-8 row jc-center ai-center text-center text-red px-5 py-1 position-relative `}>
                    <Trans
                        i18nKey="Deposit.addressExpired"
                        values={{
                            expDate: moment.utc(data[0]?.expTime).local().format("jYY/jMM/jDD"),
                            expTime: moment.utc(data[0]?.expTime).local().format("HH:mm:ss")
                        }}
                    />
                </div>
                <Button
                    type="button"
                    buttonClass={`${classes.thisButton} width-100 cursor-pointer rounded-100-p mt-2`}
                    buttonTitle={t('Deposit.getAddress')}
                    onClick={()=>{
                        setExpired(false)
                        refetch()
                    }}
                />
            </>
        }

        return <>

            <QRCode
                value={data[0]?.address}
                bgColor="var(--cardBody)"
                fgColor="var(--textColor)"
                level='L'
                size={130}
            />

            <span className={`mt-2 mb-1 font-weight-bold flex jc-center ai-center width-100 text-center ${classes?.address}`}>{data[0]?.address}</span>

            <div className={`width-100 flex jc-center ai-center mb-2`}>
                <Icon
                    iconName={`icon-copy fs-02 flex cursor-pointer`}
                    iconClass={``}
                    onClick={(e) => copyToClipboard(e, data[0]?.address)}
                />
            </div>


            <div className={`width-100 rounded-8 row jc-between ai-center ${classes.amountBox} px-5 py-1 my-1 position-relative `}>
                <span className={`width-40`}>{t("assignedDate")}</span>
                <div className={`width-60 row jc-end ai-center`}>
                    <span className={`fs-02 font-weight-bold`}><Date date={data[0]?.assignedDate}/> - {moment.utc(data[0]?.assignedDate).local().format("HH:mm:ss")}</span>
                </div>
            </div>

            <div className={`width-100 rounded-8 row jc-between ai-center ${classes.amountBox} px-5 py-1 my-1 position-relative `}>
                <span className={`width-40`}>{t("expTime")}</span>
                <div className={`width-60 row jc-end ai-center`}>
                    <span className={`fs-02 font-weight-bold`}><Date date={data[0]?.expTime}/> - {moment.utc(data[0]?.expTime).local().format("HH:mm:ss")}</span>
                </div>
            </div>

            <div className={`width-100 rounded-8 row jc-between ai-center ${classes.amountBox} px-5 py-1 my-1 position-relative `}>
                <span className={`width-40`}>{t("remainingTime")}</span>
                <div className={`width-60 row jc-end ai-center`}>
                    <span className={`fs-02 font-weight-bold`}><Countdown
                        date={parseInt(moment.utc(data[0]?.expTime).local().unix()) * 1000  }
                        renderer={props => <div className={ `${props.minutes === 0 && "text-red"} direction-ltr `}>{props.hours} : {props.minutes} : {props.seconds}</div>}
                        onComplete={() => {
                            setExpired(true)
                        }}
                    /></span>
                </div>
            </div>

            <span className={`my-1 text-center`}>
                <Trans
                    i18nKey="Deposit.addressHint"
                    components={{
                        expDate: <Date date={data[0]?.expTime}/>
                    }}
                    values={{
                        expTime: moment.utc(data[0]?.expTime).local().format("HH:mm:ss")
                    }}


                />
            </span>
        </>

    }

    return (
        <div className={`width-86 column jc-center ai-center m-auto py-5`}>
            {content()}
        </div>
    );
};

export default OnChainDepositAddress;
