import React from 'react';
import classes from './Menu.module.css';
import {Link, useLocation, useNavigate} from "react-router-dom";
import useAuth from "../../../../Hooks/useAuth";
import {useTranslation} from "react-i18next";
import * as RoutesName from "../../../../Routes/routes";
import {images} from "../../../../assets/images/images";
import packageJson from "../../../../../package.json";
import {useGetKycStatus, useGetUserInfo} from "../../../../query";
import {logout, requestOTP, revokeTokens} from "../../../../api/auth";
import toast from "react-hot-toast";
import jwtDecode from "jwt-decode";
import Countdown from "react-countdown";
import useGlobal from "../../../../Hooks/useGlobal";
import {toAbsoluteUrl} from "../../../../utils/utils";
import i18next from "i18next";

const Menu = () => {

    const {t} = useTranslation();
    const navigate = useNavigate();

    const location = useLocation();
    const {auth, setAuth} = useAuth();
    const {global, setGlobal} = useGlobal();

    const verifyToken = localStorage.getItem('token');
    const jwt = jwtDecode(verifyToken)


    const {data: ksData} = useGetKycStatus()

    const disabled = () =>{
        if (ksData?.kyc_status === "Approved") return false
        else return true
    }

    const {data, isLoading, error} = useGetUserInfo()

    const navigateToKycHandler = () => {
        setGlobal({
            ...global,
            activeActionSheet: {
                menu: false,
                select: false,
            },
        })
    }

    const navigateHandler = (route) => {
        if (ksData?.kyc_status === "Approved") {

            setGlobal({
                ...global,
                activeActionSheet: {
                    menu: false,
                    select: false,
                },
            })

            return navigate(route)
        }
        else return toast.error(t("doKYC"))
    }

    const logoutFunc = async (e) => {

        logout(auth?.token)
            .then(async (res) => {

                localStorage.clear()

                setAuth({
                    ...auth,
                    mobile: null,
                    token: null,
                    InitialToken: null,
                })

                setGlobal({
                    ...global,
                    hideUserBalance: true,
                    activeActionSheet: {
                        menu: false,
                        select: false,
                    },
                    selectType: null,
                })
                navigate(RoutesName.Login)

            }).catch(err => {

                if (i18next.exists('ErrorMessages.'+ err?.response?.data?.message)) {
                    toast.error(t("ErrorMessages."+ err?.response?.data?.message))
                }
                else {
                    toast.error(t("serverError"))
                }

        }).finally(() => {

        })
    }

    /*const onComplete = () => {
        toast.error("پایان اعتبار جلسه کاربری! لطفا دوباره وارد شوید.",{duration: Infinity})
    }*/

    return (
        <div className={`width-100 ${classes.container} column jc-between ai-center pb-2`}>
            <div className={`column jc-start ai-center mt-2 mb-2 width-100`}>
                {isLoading ? <span className={`${classes.loading} rounded-4 width-38`}/> :
                    <span className={`font-weight-bold fs-03`}>{data?.first_name} {data?.last_name}</span>
                }
                {isLoading ? <span className={`${classes.loading} rounded-4 width-38 my-1`}/> :
                    <span className={`text-gray mt-025`}>{data?.email}</span>
                }
                {isLoading ? <span className={`${classes.loading} rounded-4 width-38`}/> :
                    <span className={`text-gray direction-ltr unicode-bidi-embed`}>{data?.mobile}</span>
                }
            </div>

            {ksData?.kyc_status === "NoKYC" && <span className={`text-red mb-2`}>{t("KYCStatus." + ksData?.kyc_status)}</span>}

            <div className={`width-86 font-weight-bold fs-01 column jc-start ai-start`}>
                <Link to={RoutesName.UpdateProfile} className={`${classes.link} py-1 cursor-pointer width-100`}>{t("UpdateProfile.title")}</Link>
                <Link to={RoutesName.Authentication} className={`${classes.link} py-1 cursor-pointer width-100`}>{t("Authentication.title")}</Link>
                <Link to={RoutesName.TransactionPassword} className={`${classes.link} py-1 cursor-pointer width-100`}>{t("TransactionPassword.title")}</Link>
                <Link to={RoutesName.History} className={`${classes.link} py-1 cursor-pointer width-100`}>{t("History.title")}</Link>
                <div onClick={()=>navigateHandler(RoutesName.Accounts)} className={`${classes.link} py-1 cursor-pointer width-100 ${disabled() && "text-gray"}`}>{t("LinkedBankAccounts.title")}</div>
                <Link to={RoutesName.ContactUs} className={`${classes.link} py-1 cursor-pointer width-100`}>{t("Contact.title")}</Link>
                <Link to={RoutesName.Rules} className={`${classes.link} py-1 cursor-pointer width-100`}>{t("Rules.title")}</Link>
                <div onClick={logoutFunc} className={`${classes.link} py-1 cursor-pointer width-100 row jc-between ai-center`}>
                    <span className={`width-30`}>{t("Logout.title")}</span>
                    {jwt?.exp &&<div className={`width-70 row jc-end ai-center`}>
                        <span className={`ml-2 fs-0-9`}>{t("remainingTime")}</span>
                        <span className={`font-weight-bold fs-02 width-25`}><Countdown
                            date={parseInt(jwt?.exp * 1000)  }
                            renderer={props => <div className={ `${props.minutes === 0 && "text-red"} direction-ltr `}>{props.minutes} : {props.seconds}</div>}
                            /*onComplete={() => setAuth(prevAuth => ({ ...prevAuth, isExpired: true }))}*/
                        /></span>
                    </div>}
                </div>
            </div>

            <div className={`column jc-end ai-center width-100 mt-3`}>
                <img src={toAbsoluteUrl('/assets/logo/lightLogo.svg')} className={`${classes.logo}`} alt=""/>
                <span className={`fs-0-9 text-gray mt-05`}>{packageJson.version}</span>
            </div>

        </div>
    );
};

export default Menu;
