import React, {useEffect, useState} from 'react';
import "./Styles/Mobile.css";
import * as RoutesName from "../../Routes/routes";
import i18n from "i18next";

import {Navigate, Route, Routes, useLocation} from "react-router-dom";
import Layout from "./Layout/Layout";
import Home from "./Pages/Home/Home";
import RequireAuth from "../../components/RequireAuth/RequireAuth";
import Login from "./Pages/Login/Login";
import Password from "./Pages/Password/Password";
import Otp from "./Pages/Otp/Otp";
import Register from "./Pages/Register/Register";
import Transfer from "./Pages/Transfer/Transfer";
import Exchange from "./Pages/Exchange/Exchange";
import Transaction from "./Pages/Transaction/Transaction";
import Authentication from "./Pages/Authentication/Authentication";
import Loading from "../../components/Loading/Loading";
import Chat from "./Pages/Chat/Chat";
import UploadImages from "./Pages/Authentication/Module/UploadImages/UploadImages";
import jwtDecode from "jwt-decode";
import Guide from "./Pages/Authentication/Module/Guide/Guide";
import KycStatus from "./Pages/Authentication/Module/KycStatus/KycStatus";
import Accounts from "./Pages/Accounts/Accounts";
import UpdateProfile from "./Pages/UpdateProfile/UpdateProfile";
import TransactionPassword from "./Pages/TransactionPassword/TransactionPassword";
import {useGetCurrencies, useGetDashboard, useGetUserWallet} from "../../query";
import TransactionsHistory from "./Pages/TransactionsHistory/TransactionsHistory";
import Withdrawal from "./Pages/Withdrawal/Withdrawal";
import Deposit from "./Pages/Deposit/Deposit";
import {loadConfig} from "../../utils/loadConfig";
import useAuth from "../../Hooks/useAuth";
import useGlobal from "../../Hooks/useGlobal";
import LoadConfigLoading from "../../components/LoadConfigLoading/LoadConfigLoading";
import LoadConfigError from "../../components/LoadConfigError/LoadConfigError";
import ForgetPassword from "./Pages/ForgetPassword/ForgetPassword";
import Symbol from "./Pages/Symbol/Symbol";
import AccessDenied from "../../components/AccessDenied/AccessDenied";
import Missing from "../../components/Missing/Missing";
import Unauthorized from "../../components/Unauthorized/Unauthorized";
import ContactUs from "./Pages/ContactUs/ContactUs";
import Rules from "./Pages/Rules/Rules";
import UpdateProfileFu from "./Pages/UpdateProfile/UpdateProfileFu";
import UserUpdateProfile from "./Pages/UpdateProfile/UserUpdateProfile";
import PhoneLogin from "./Pages/Login/PhoneLogin";
import History from "./Pages/History/History";
import {Tooltip} from "react-tooltip";
import {isBrowser} from "react-device-detect";
import UploadDocuments from "./Pages/Authentication/Module/UploadDocuments/UploadDocuments";
import TokenExpired from "../../components/TokenExpired/TokenExpired";
import PaymentResult from "./Pages/PaymentResult/PaymentResult";

const Mobile = () => {

    const location = useLocation();

    const {auth, setAuth} = useAuth();
    const {global, setGlobal} = useGlobal();

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        document.title = window.env.REACT_APP_TITLE;
    }, [])


    useEffect(() => {

        if (global?.direction) {
            document.body.classList.add(`${global?.direction}`)

            i18n.on("languageChanged", () => {
                global?.direction !== "rtl" ? document.body.classList.add('ltr') : document.body.classList.remove('ltr');
            });
        }

    }, [global?.direction]);


/*
    const meta = document.getElementsByTagName('meta')

    meta.viewport.content = "height=925, width=450, user-scalable=no, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0"

    console.log("meta", meta?.viewport?.content)
*/





    useEffect(() => {

        let newAuth = {...auth}
        let newGlobal = {...global}

        const InitialToken = localStorage.getItem('InitialToken');
        const verifyToken = localStorage.getItem('token');
        const mobile = localStorage.getItem('mobile');
        const otpLock = localStorage.getItem('otpLock');
        const hideUserBalance = JSON.parse(localStorage.getItem('hideUserBalance'));


        if (mobile && !verifyToken) {

            newAuth = {
                ...newAuth,
                token: verifyToken,
                mobile: mobile,
                InitialToken: InitialToken,
            }

            newGlobal = {
                ...newGlobal,
                otpLock: otpLock,
                hideUserBalance: hideUserBalance,
            }

        }
        if (mobile && verifyToken) {



            const jwt = jwtDecode(verifyToken)
            const currentTime = Date.now()

            if (currentTime > jwt.exp * 1000) {

                localStorage.clear()
                newAuth = {
                    ...newAuth,
                    token: null,
                    mobile: null,
                    InitialToken: null,
                }

                newGlobal = {
                    ...newGlobal,
                    otpLock: null,
                    hideUserBalance: true,
                }

            } else {

                newAuth = {
                    ...newAuth,
                    token: verifyToken,
                    mobile: mobile,
                    InitialToken: null,
                }

                newGlobal = {
                    ...newGlobal,
                    otpLock: otpLock,
                    hideUserBalance: hideUserBalance,
                }
            }
        }
        setAuth(newAuth)
        /*setGlobal(newGlobal)*/

        loadConfig(newGlobal, setGlobal)

        setLoading(false)
    }, []);


    if (loading || global?.isLoading) return <LoadConfigLoading/>
    if ( global?.accessDenied) return <AccessDenied/>
    if ( global?.hasError) return <LoadConfigError/>

    if (auth?.isExpired) return <TokenExpired/>


    return (
        <>

            <Routes>
                <Route element={<Layout/>}>
                    <Route path={RoutesName.Login} element={<PhoneLogin/>}/>
                    <Route path={RoutesName.PaymentResult} element={<PaymentResult/>}/>
                    <Route path={RoutesName.Password} element={<Password/>}/>
                    <Route path={RoutesName.Otp} element={<Otp/>}/>
                    <Route path={RoutesName.ForgetPassword} element={<ForgetPassword/>}/>
                    <Route path={RoutesName.Register} element={<Register/>}/>
                    <Route element={<RequireAuth/>}>
                        <Route path={RoutesName.Home} element={<Home/>}/>
                        <Route path={RoutesName.Markets + ":symbol"} element={<Symbol/>}/>
                        <Route path={RoutesName.Transfer} element={<Transfer/>}/>
                        <Route path={RoutesName.Exchange} element={<Exchange/>}/>
                        <Route path={RoutesName.Withdrawal} element={<Withdrawal/>}/>
                        <Route path={RoutesName.Deposit} element={<Deposit/>}/>
                        <Route path={RoutesName.Accounts} element={<Accounts/>}/>
                        <Route path={RoutesName.Transaction} element={<Transaction/>}/>

                        <Route path={RoutesName.Authentication} element={<Authentication/>}/>

                        <Route path={RoutesName.Guide} element={<Guide/>}/>

                        <Route path={RoutesName.UploadImages} element={<UploadImages/>}/>
                        <Route path={RoutesName.UploadDocuments} element={<UploadDocuments/>}/>

                        <Route path={RoutesName.KYCStatus} element={<KycStatus/>}/>

                        <Route path={RoutesName.Chat} element={<Chat/>}/>
                        <Route path={RoutesName.UpdateProfile} element={<UserUpdateProfile/>}/>
                        <Route path={RoutesName.TransactionPassword} element={<TransactionPassword/>}/>
                        {/*<Route path={RoutesName.TransactionsHistory} element={<TransactionsHistory/>}/>*/}
                        <Route path={RoutesName.HistoryRelative+"/*"} element={<History/>}/>
                        <Route
                            path={RoutesName.HistoryRelative}
                            element={<Navigate to={RoutesName.TransactionsHistory} replace />}
                        />

                        <Route path={RoutesName.ContactUs} element={<ContactUs/>}/>
                        <Route path={RoutesName.Rules} element={<Rules/>}/>
                    </Route>
                    <Route path="unauthorized" element={<Unauthorized/>}/>
                </Route>
                <Route path="*" element={<Missing/>}/>
            </Routes>
            { isBrowser && <Tooltip id="polban-tooltip"/>}
        </>

    );
};

export default Mobile;
