import React from 'react';
import classes from '../CurrencyCard/CurrencyCard.module.css'
import {useGetCurrencies, useGetDashboard} from "../../../../../../query";
import {useTranslation} from "react-i18next";
import useAuth from "../../../../../../Hooks/useAuth";
import Coin from "../../../../Pages/Home/Module/Coin/Coin";
import CurrencyCard from "../CurrencyCard/CurrencyCard";
import useGlobal from "../../../../../../Hooks/useGlobal";
import Button from "../../../../../../components/Button/Button";
import * as RoutesName from "../../../../../../Routes/routes";
import {useLocation, useNavigate} from "react-router-dom";

const Currencies = ({filter, all}) => {


    const {t} = useTranslation();
    const navigate = useNavigate();

    const {global, setGlobal} = useGlobal();

    const location = useLocation()
    const pathname = location?.pathname

    /*const {data, isLoading, error} = useGetDashboard()*/

    const showAllHandler = (coin) => {

        let newGlobal = {...global}


        newGlobal = {
            ...newGlobal,
            activeActionSheet: {
                menu: false,
                select: false,
            },
            selectType: null,
        }

        if ((pathname?.includes("history")) && (pathname?.includes("transactions"))) {
            newGlobal = {
                ...newGlobal,
                selected_transactions_history_currency: null,
            }
        }
        if ((pathname?.includes("history")) && (pathname?.includes("deposit"))) {
            newGlobal = {
                ...newGlobal,
                selected_deposit_history_currency: null,
            }
        }
        if ((pathname?.includes("history")) && (pathname?.includes("withdraw"))) {
            newGlobal = {
                ...newGlobal,
                selected_withdraw_history_currency: null,
            }
        }
        if ((pathname?.includes("history")) && (pathname?.includes("swap"))) {

            if (global?.selectType === "history_source_currency") {
                newGlobal = {
                    ...newGlobal,
                    selected_swap_history_source_currency: null,
                }
            }
            if (global?.selectType === "history_dest_currency") {
                newGlobal = {
                    ...newGlobal,
                    selected_swap_history_dest_currency: null,
                }
            }


        }

        setGlobal(newGlobal)

    }


    const content = () => {
        /*if (isLoading) {
            return <span className={`my-10`}>{t("loading")}</span>
        }
        if (error) {
            return <span className={`my-10`}>{t("error")}</span>
        }
        if (Object.keys(data).length <= 0) {
            return <span className={`my-10`}>{t("noData")}</span>
        }*/


    /*.filter((f) => global?.currencies[f]?.isActive && !global?.currencies[f]?.isTransitive)*/


        if (global?.selectType === "deposit_coin") {

            if (Object.keys(global?.currencies).filter((f) =>(global?.currencies[f]?.isActive) && (!global?.currencies[f]?.isTransitive) && (global?.currencies[f]?.depositAllowed)).length === 0) {
                return <div className={`my-10 width-100 column jc-center ai-center`}>
                    <span className={`mb-3`}>{t("noData")}</span>
                    <Button
                        type="button"
                        buttonClass={`${classes.thisButton} width-40 cursor-pointer rounded-100-p mt-3`}
                        buttonTitle={t('home')}
                        onClick={()=>navigate(RoutesName.Home)}
                    />
                </div>
            }

            return Object.keys(global?.currencies)
                .filter((f) =>  (global?.currencies[f]?.isActive) && (!global?.currencies[f]?.isTransitive) && (global?.currencies[f]?.availableGatewayType !== null) )
                .sort((a, b) => global?.currencies[b].depositAllowed - global?.currencies[a].depositAllowed)
                .map(key => <CurrencyCard data={global?.currencies[key]} key={key}/>)

           /* return Object.keys(global?.currencies)
                .filter((f) =>  (global?.currencies[f]?.isActive) && (!global?.currencies[f]?.isTransitive) && (global?.currencies[f]?.depositAllowed) )
                .map(key => <CurrencyCard data={global?.currencies[key]} key={key}/>)*/
        }



        if (global?.selectType === "withdrawal_coin") {

            if (Object.keys(global?.currencies).filter((f) =>  (global?.currencies[f]?.isActive) && (!global?.currencies[f]?.isTransitive) && (global?.currencies[f]?.withdrawAllowed)).length === 0) {
                return <div className={`my-10 width-100 column jc-center ai-center`}>
                    <span className={`mb-3`}>{t("noData")}</span>
                    <Button
                        type="button"
                        buttonClass={`${classes.thisButton} width-40 cursor-pointer rounded-100-p mt-3`}
                        buttonTitle={t('home')}
                        onClick={()=>navigate(RoutesName.Home)}
                    />
                </div>
            }

            return Object.keys(global?.currencies)
                .filter((f) =>  (global?.currencies[f]?.isActive) && (!global?.currencies[f]?.isTransitive) && (global?.currencies[f]?.availableGatewayType !== null) )
                .sort((a, b) => global?.currencies[b].withdrawAllowed - global?.currencies[a].withdrawAllowed)
                .map(key => <CurrencyCard data={global?.currencies[key]} key={key}/>)
        }


        if (filter) {
            return Object.keys(global?.currencies).filter((f) => (f !== filter) && (global?.currencies[f]?.isActive) && (!global?.currencies[f]?.isTransitive) ).map(key => <CurrencyCard data={global?.currencies[key]} key={key}/>)
        }

        return <>

            {all && <div className={`${classes.asset} row jc-between ai-center py-2 my-2 px-3 rounded-8`} onClick={()=>showAllHandler()}>
                <span>{t("all")}</span>
            </div>}


            {Object.keys(global?.currencies).filter((f) =>  (!global?.currencies[f]?.isTransitive) ).map(key => <CurrencyCard data={global?.currencies[key]} key={key}/>)}
        </>
    }

    return (
        content()
    );
};

export default Currencies;
