import React from 'react';
import classes from './CurrencyCard.module.css'
import {useTranslation} from "react-i18next";
import useAuth from "../../../../../../Hooks/useAuth";
import useGlobal from "../../../../../../Hooks/useGlobal";
import toast from "react-hot-toast";
import {useLocation} from "react-router-dom";
import {getCurrencyNameOrAlias} from "../../../../../../utils/utils";

const CurrencyCard = ({data}) => {

    const {t} = useTranslation();

    const location = useLocation()
    const pathname = location?.pathname

    const {global, setGlobal} = useGlobal();

    const direction = global?.direction
    const language = global?.language

    const isDeposit = global?.selectType === "deposit_coin"
    const isWithdraw = global?.selectType === "withdrawal_coin"

    const isAllowed = (global?.selectType === "deposit_coin" && data?.depositAllowed)
        || (global?.selectType === "withdrawal_coin" && data?.withdrawAllowed)
        || (global?.selectType === "exchange_base") || (global?.selectType === "exchange_quote")
        || global?.selectType === "history_currency"
        || global?.selectType === "history_source_currency"
        || global?.selectType === "history_dest_currency"


    const selectedCoinHandler = (currency) => {

        if (!isAllowed) {
            return toast.error( isDeposit ?  t("depositCurrencyIsNotAllowed") : t("withdrawCurrencyIsNotAllowed"))
        }

        let newGlobal = {...global}

        if ((pathname?.includes("history")) && (pathname?.includes("transactions"))) {
            newGlobal = {
                ...newGlobal,
                activeActionSheet: {
                    menu: false,
                    select: false,
                },
                selectType: null,
                ["selected_" + "transactions_" + global?.selectType]: currency
            }
        }
        if ((pathname?.includes("history")) && (pathname?.includes("deposit"))) {
            newGlobal = {
                ...newGlobal,
                activeActionSheet: {
                    menu: false,
                    select: false,
                },
                selectType: null,
                ["selected_" + "deposit_" + global?.selectType]: currency
            }
        }
        if ((pathname?.includes("history")) && (pathname?.includes("withdraw"))) {
            newGlobal = {
                ...newGlobal,
                activeActionSheet: {
                    menu: false,
                    select: false,
                },
                selectType: null,
                ["selected_" + "withdraw_" + global?.selectType]: currency
            }
        }
        if ((pathname?.includes("history")) && (pathname?.includes("swap"))) {
            newGlobal = {
                ...newGlobal,
                activeActionSheet: {
                    menu: false,
                    select: false,
                },
                selectType: null,
                ["selected_" + "swap_" + global?.selectType]: currency
            }
        }

        else {
            newGlobal = {
                ...newGlobal,
                activeActionSheet: {
                    menu: false,
                    select: false,
                },
                selected_withdrawal_network: global?.selected_withdrawal_coin === currency ? global?.selected_withdrawal_network : null,
                selected_deposit_network: global?.selected_deposit_coin === currency ? global?.selected_deposit_network : null,
                selected_withdrawal_iban: null,
                selectType: null,
                ["selected_" + global?.selectType]: currency
            }
        }




        setGlobal(newGlobal)

    }

    return (
        <div className={`${classes.asset} ${!isAllowed && classes.disabled  } row jc-between ai-center py-1 my-2 px-3 rounded-8`} onClick={()=>selectedCoinHandler(data?.symbol)}>
            <div className={`width-30 flex jc-start ai-center`}>
                <img src={data?.icon} alt="" className={`${classes.icon} ${!isAllowed && "grayscale-filter-100"}`}/>
            </div>
            <div className={`width-35 row jc-start ai-center`}>
                <span className={`abridge font-weight-bold`}>{getCurrencyNameOrAlias(data, language)}</span>
            </div>
            <div className={`width-35 row jc-end ai-center`}>
                <span className={`text-gray`}>{data?.symbol}</span>
            </div>
        </div>
    );
};

export default CurrencyCard;
