import React, {useState} from 'react';
import classes from './LastPrice.module.css'
import {Trans, useTranslation} from "react-i18next";
import {useGetDashboard} from "../../../../../../query";
import Coin from "../Coin/Coin";
import useGlobal from "../../../../../../Hooks/useGlobal";
import {Link} from "react-router-dom";
import * as Routes from "../../../../../../Routes/routes";
import Icons from "../../../../../../components/Icon/Icons";
import i18n from "i18next";
import {getCurrencyNameOrAlias} from "../../../../../../utils/utils";

const LastPrice = () => {

    const {t} = useTranslation();

    /*const {data, isLoading, error} = useGetDashboard()*/


    const {global} = useGlobal();

    const language = global?.language

    const useByeAndSellPrice = window.env.REACT_APP_USE_BUY_AND_SELL_PRICE.toLowerCase() === "true"

    const [activeRef, setActiveRef] = useState(global?.refs[0])

    const content = () => {

        /*if (isLoading) {
            return <div className={`width-100 column jc-center ai-center height-100 my-1`}>
                <div className={`width-90 my-1 row jc-between ai-center`}>
                    <div className={`${classes.circle}`}/>
                    <div className={`${classes.rectangle} width-76 rounded-8`}/>
                </div>
                <div className={`width-90 my-1 row jc-between ai-center`}>
                    <div className={`${classes.circle}`}/>
                    <div className={`${classes.rectangle} width-76 rounded-8`}/>
                </div>
                <div className={`width-90 my-1 row jc-between ai-center`}>
                    <div className={`${classes.circle}`}/>
                    <div className={`${classes.rectangle} width-76 rounded-8`}/>
                </div>
            </div>
        }
        if (error) {
            return <span className={`my-10`}>{t("error")}</span>
        }
        if (Object.keys(data).length <= 0) {
            return <span className={`my-10`}>{t("noData")}</span>
        }*/



        return <>
            { useByeAndSellPrice && <div className={`width-100 ${classes.header}`}>
                <div className={`width-90 row jc-between ai-center m-auto py-2`}>
                    <span className={`font-weight-bold fs-02`}><Trans
                        i18nKey="marketAppTitle"
                        values={{
                            title: t("title"),
                        }}
                    /></span>
                    <div className={`row`}>
                        {global?.refs?.map((ref) =>
                            <span
                                className={` rounded-5 cursor-pointer hover-text ${classes.refTitle} ${activeRef === ref && classes.active}`}
                                onClick={() => setActiveRef(ref)} key={ref}>{getCurrencyNameOrAlias(global?.currencies[ref], language)}</span>
                        )}
                    </div>
                </div>
            </div>}


            {
                useByeAndSellPrice ?
                Object.keys(global?.currencies).filter((f) => global?.currencies[f]?.isActive && !global?.currencies[f]?.isTransitive && (f !== activeRef)).map(key => <Coin data={global?.currencies[key]} key={key} rate={global?.prices[activeRef]?.[key]} sourceRate={activeRef}/>)
                    :
                    Object.keys(global?.currencies).filter((f) => global?.currencies[f]?.isActive && !global?.currencies[f]?.isTransitive).map(key => <Coin data={global?.currencies[key]} key={key} rate={global?.prices[activeRef]?.[key]} sourceRate={activeRef}/>)

            }
        </>




    }

    return (
        <div className={`width-86 ${classes.container} mt-4 mb-4 column jc-center ai-center rounded-8`}>
            {content()}
        </div>
    );
};

export default LastPrice;
