import React, {useEffect, useState} from 'react';
import classes from './UserUpdateProfile.module.css';
import ScrollBar from "../../../../components/ScrollBar";
import Button from "../../../../components/Button/Button";
import {Trans, useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {validateEmail} from "../../../../utils/utils";
import TextInput from "../../../../components/TextInput/TextInput";
import {useGetKycStatus, useGetUserInfo} from "../../../../query";
import Loading from "../../../../components/Loading/Loading";
import {updateProfile} from "../../../../api/auth";
import toast from "react-hot-toast";
import useAuth from "../../../../Hooks/useAuth";
import i18next from "i18next";

const UserUpdateProfile = () => {

    const {t} = useTranslation();

    const {auth} = useAuth();

    const {data:userInfo , isLoading, error, refetch} = useGetUserInfo()
    const {data: ksData , isLoading: ksDataIsLoading } = useGetKycStatus()

    const [loading, setLoading] = useState(false)

    const [input, setInput] = useState({
        firstname: {value: "", error: []},
        lastname: {value: "", error: []},
        identityID: {value: "", error: []},
        email: {value: "", error: []},
    });

    useEffect(() => {
        if (userInfo) {
            setInput({
                firstname: {value: userInfo?.first_name !== null ? userInfo?.first_name : "", error: []},
                lastname: {value: userInfo?.last_name !== null ? userInfo?.last_name : "", error: []},
                identityID: {value: userInfo?.identity_id !== null ? userInfo?.identity_id : "", error: []},
                email: {value: userInfo?.email !== null ? userInfo?.email : "", error: []},
            })
        }
    }, [userInfo])


    const inputHandler = (e) => {
        let errorMessage = []
        let inputVal = e.target.value
        if (typeof e.target.dataset.min !== undefined && e.target.value.length < e.target.dataset.min) {
            errorMessage.push(<Trans
                i18nKey="Login.minInput"
                values={{
                    name: t(e.target.dataset.name),
                    min: e.target.dataset.min
                }}
            />)
        }

        if (e.target.dataset?.type === "email" && !validateEmail(e.target.value)) {
            errorMessage.push(t('wrongEmail'))
        }

        if (e.target.dataset?.name === "confirmPassword" && e.target.value !== input.password.value) {
            errorMessage.push([t('wrongPasswordConfirmation')])
        }

        let prevState = {
            ...input,
            [e.target.dataset.name]: {...input[e.target.dataset.name], value: inputVal, error: errorMessage}
        }

        if (e.target.dataset?.name === "password") {
            prevState.confirmPassword.error = (e.target.value === input.confirmPassword.value || input.confirmPassword.value.length === 0) ? [] : [t('wrongPasswordConfirmation')]
        }

        setInput(prevState)
    }

    const isFormValid = () => {

        let inputs = {...input}

        const hasError = Object.values(input).find(input => input.error.length > 0)
        if (hasError) return false
        let isEmpty = false

        for (const key in inputs) {
            if (inputs[key].value.length === 0) {
                isEmpty = true
                inputs = {
                    ...inputs,
                    [key]: {
                        ...inputs[key],
                        error: [<Trans
                            i18nKey="Login.emptyInput"
                            values={{
                                name: t(key),
                            }}
                        />]
                    }
                }
            }
        }

        setInput(inputs);
        return !isEmpty;
    }

    const submit = async (e) => {

        e.preventDefault();

        if (!isFormValid()) return false;

        setLoading(true)

        const updateProfileParam = {
            "first_name": input.firstname.value,
            "last_name": input.lastname.value,
            "identity_id": input.identityID.value,
            "email": input.email.value,
        }

        updateProfile(updateProfileParam, auth?.token)
            .then(async (res) => {
                toast.success(t("UpdateProfile.success"))
                refetch()
            }).catch(err => {
                if (i18next.exists('ErrorMessages.'+ err?.response?.data?.message)) {
                    toast.error(t("ErrorMessages."+ err?.response?.data?.message))
                }
                else {
                    toast.error(t("serverError"))
                }
        }).finally(() => {
            setLoading(false)
        })
    }


    const buttonTitleHandler = () => {
        if (isLoading) return t('update')
        if (loading) return <Loading type="text"/>
        return t('update')
    }

    const content = () => {
      if (isLoading) return <>
          <div className={`${classes.loading} width-85 my-1`}/>
          <div className={`${classes.loading} width-85 my-1`}/>
          <div className={`${classes.loading} width-85 my-1`}/>
          <div className={`${classes.loading} width-85 my-1`}/>
      </>
      if (error) return <>
          <span className={`my-10`}>{t("error")}</span>
      </>
      return <>
            <TextInput
                value={input.firstname.value}
                type="text"
                label={t('firstname')}
                id="firstname"
                labelFor="firstname"
                //placeholder={t('Login.mobilePh')}
                data-name="firstname"
                data-type="input"
                data-min={2}
                //maxLength="10"
                onchange={(e) => inputHandler(e)}
                alerts={input.firstname.error}
                inputClass={`width-85 my-1`}
                disabled={(!ksDataIsLoading && (ksData?.kyc_status === "Approved"))}
            />
            <TextInput
                value={input.lastname.value}
                type="text"
                label={t('lastname')}
                id="lastname"
                labelFor="lastname"
                //placeholder={t('Login.mobilePh')}
                data-name="lastname"
                data-type="input"
                data-min={2}
                //maxLength="10"
                onchange={(e) => inputHandler(e)}
                alerts={input.lastname.error}
                inputClass={`width-85 my-1`}
                disabled={(!ksDataIsLoading && (ksData?.kyc_status === "Approved"))}
            />
            <TextInput
                value={input.identityID.value}
                type="text"
                label={t('identityID')}
                id="identityID"
                labelFor="identityID"
                //placeholder={t('Login.mobilePh')}
                data-name="identityID"
                data-type="input"
                data-min={5}
                //maxLength="10"
                onchange={(e) => inputHandler(e)}
                alerts={input.identityID.error}
                inputClass={`width-85 my-1 numeric-ltr`}
                disabled={(!ksDataIsLoading && (ksData?.kyc_status === "Approved"))}
            />
            <TextInput
                value={input.email.value}
                type="text"
                label={t('email')}
                id="email"
                labelFor="email"
                //placeholder={t('Login.mobilePh')}
                data-name="email"
                data-type="email"
                //maxLength="10"
                onchange={(e) => inputHandler(e)}
                alerts={input.email.error}
                inputClass={`width-85 my-1 numeric-ltr`}
                disabled={(!ksDataIsLoading && (ksData?.kyc_status === "Approved"))}
            />

        </>
    }


    return (
        <ScrollBar>
            <form onSubmit={(e)=>submit(e)} className={`${classes.container} column jc-between ai-center py-3 px-5`}>
                <div className={`width-86 column jc-center ai-center`}>
                    { ksData?.kyc_status === "Approved" && <div className={`rounded-8 flex jc-center ai-start ${classes.noteBox} px-5 py-2 mt-2 mb-3`}>
                        <span className={`text-green text-center`}>{t("UpdateProfile.kycApproved")}</span>
                    </div> }
                    <div className={`column jc-center ai-center width-100 my-3`}>
                        {content()}
                    </div>
                </div>
                <Button
                    type="submit"
                    buttonClass={`${classes.thisButton} width-100 cursor-pointer rounded-100-p mb-1`}
                    buttonTitle={buttonTitleHandler()}
                    disabled={loading || isLoading || ksData?.kyc_status === "Approved"}
                />
            </form>
        </ScrollBar>
    );
};

export default UserUpdateProfile;
