import React, { useState } from "react";
import DatePro from "../DatePro/DatePro";

const DateDisplay = ({ date, calendarType, localeType, classes }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleDateFormat = () => setIsExpanded((prev) => !prev);

    return (
        <div
            className={`${classes.dateDivider} width-50 flex jc-center ai-center px-5 py-1 cursor-pointer`}
            onClick={toggleDateFormat}
        >
            <DatePro
                date={date}
                formatType={isExpanded ? "monthAndYear" : "month"}
                calendarType={calendarType}
                localeType={localeType}
            />
        </div>
    );
};

export default DateDisplay;
