import React from 'react';
import moment from "moment-jalaali";
import momentHijri from "moment-hijri";
import "moment-hijri";
import useGlobal from "../../Hooks/useGlobal";

const Date = ({date}) => {

    const {global} = useGlobal();
    const type = global?.calendar

    const calendar = () => {

        switch (type) {
            case "jalali":
                return moment.utc(date).local().format("jYY/jMM/jDD");
            case "gregorian":
                return moment.utc(date).local().format("YY/MM/DD");
            case "hijri":
                return  momentHijri.utc(date).local().format("iYYYY/iMM/iDD");
            default:
                return moment.utc(date).local().format("YY/MM/DD");
        }
    };

    return (<>{calendar()}</>);
};

export default Date;
