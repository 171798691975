import React from "react";
import DateObject from "react-date-object";

const DatePro = ({ date, formatType = "fullDate", calendarType, localeType }) => {

    const removePrefix = (formattedDate, calendarTypeName) => {
        if (calendarTypeName === "jalali") {
            return formattedDate.replace(/\bj/g, "");
        }
        if (calendarTypeName === "hijri") {
            return formattedDate.replace(/\bi/g, "");
        }
        return formattedDate;
    };

    const formattedDate = () => {
        const dateObject = new DateObject({
            date: new Date(date).toISOString(),
            calendar: calendarType,
            locale: localeType,
        });

        let formatted;
        switch (formatType) {
            case "month":
                switch (calendarType.name) {
                    case "jalali":
                        formatted = dateObject.format("jD jMMMM");
                        break;
                    case "gregorian":
                        formatted = dateObject.format("D MMMM");
                        break;
                    case "hijri":
                        formatted = dateObject.format("iD iMMMM");
                        break;
                    default:
                        formatted = dateObject.format("D MMMM");
                }
                break;

            case "monthAndYear":
                switch (calendarType.name) {
                    case "jalali":
                        formatted = dateObject.format("jD jMMMM jYYYY");
                        break;
                    case "gregorian":
                        formatted = dateObject.format("D MMMM YYYY");
                        break;
                    case "hijri":
                        formatted = dateObject.format("iD iMMMM iYYYY");
                        break;
                    default:
                        formatted = dateObject.format("D MMMM YYYY");
                }
                break;

            case "fullDate":
            default:
                switch (calendarType.name) {
                    case "jalali":
                        formatted = dateObject.format("jYYYY/jMM/jDD");
                        break;
                    case "gregorian":
                        formatted = dateObject.format("YYYY/MM/DD");
                        break;
                    case "hijri":
                        formatted = dateObject.format("iYYYY/iMM/iDD");
                        break;
                    default:
                        formatted = dateObject.format("YYYY/MM/DD");
                }
        }

        return removePrefix(formatted, calendarType.name);
    };

    return <>{formattedDate()}</>;
};

export default DatePro;
