import gregorian from "react-date-object/calendars/gregorian";
import jalali from "react-date-object/calendars/jalali";
import hijri from "react-date-object/calendars/arabic";

import englishGregorian from "react-date-object/locales/gregorian_en";
import farsiGregorian from "react-date-object/locales/gregorian_fa";
import arabicGregorian from "react-date-object/locales/gregorian_ar";

import englishJalali from "react-date-object/locales/persian_en";
import farsiJalali from "react-date-object/locales/persian_fa";
import arabicJalali from "react-date-object/locales/persian_ar";

import englishHijri from "react-date-object/locales/arabic_en";
import farsiHijri from "react-date-object/locales/arabic_fa";
import arabicHijri from "react-date-object/locales/arabic_ar";

export const calendarsMap = {
    gregorian,
    jalali,
    hijri,

    englishGregorian,
    farsiGregorian,
    arabicGregorian,

    englishJalali,
    farsiJalali,
    arabicJalali,

    englishHijri,
    farsiHijri,
    arabicHijri,
};
